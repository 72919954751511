import { useResizeObserver } from '@react-aria/utils';
import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';
import { isFunction } from 'lodash-es';
import React from 'react';
import { Group } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { FieldError, FieldProps } from '../../atoms/field/field';
import Icon from '../../atoms/icon/icon';
import { Pressable } from '../../atoms/pressable/pressable';
import { fieldLabel } from '../../electrons/field';
import { input, inputContainer } from '../../electrons/input';
import { listBox, listBoxItem, listBoxTrigger, listBoxTriggerIcon } from '../../electrons/list-box';
import { popover } from '../../electrons/popover';

export interface TagFieldProps<Item> extends Omit<FieldProps, 'label'> {
  items: Item[];
  children: (item: Item) => React.ReactNode | React.ReactNode;
  getItemLabel: (item: Item) => string;
  getItemId: (item: Item) => string | number;
  onInputChange?: (value: string) => void;
  defaultSelectedItems?: Item[];
  onChange?: (selectedItems: Item[]) => void;
  name?: string;
  placeholder?: string;
  isDisabled?: boolean;
  label?: string;
  hidden?: boolean;
}

export function TagField<Item extends object>({
  items,
  label,
  children,
  getItemLabel,
  getItemId,
  onInputChange,
  defaultSelectedItems = [],
  name,
  placeholder,
  isDisabled,
  errorMessage,
  onChange,
  hidden = false,
}: TagFieldProps<Item>) {
  const [internalSelectedItems, setInternalSelectedItems] =
    React.useState<Item[]>(defaultSelectedItems);

  const triggerRef = React.useRef<HTMLDivElement>(null);
  const [triggerWidth, setTriggerWidth] = React.useState<string | null>(null);

  const onResize = React.useCallback(() => {
    if (triggerRef.current) {
      const inputRect = triggerRef.current.getBoundingClientRect();
      const minX = inputRect.left;
      const maxX = inputRect.right;
      setTriggerWidth(maxX - minX + 'px');
    }
  }, []);

  React.useEffect(() => {
    if (isFunction(onChange)) {
      onChange(internalSelectedItems);
    }
  }, [internalSelectedItems, onChange]);

  useResizeObserver({
    ref: triggerRef,
    onResize: onResize,
  });

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems: internalSelectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (newSelectedItems) {
            setInternalSelectedItems(newSelectedItems);
          }
          break;
        default:
          break;
      }
    },
  });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    itemToString(item) {
      return item ? getItemLabel(item) : '';
    },
    stateReducer(_, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            if (isFunction(onInputChange)) {
              onInputChange('');
            }

            setInternalSelectedItems((selectedItems) => {
              const itemValue = getItemId(newSelectedItem);

              const isSelected = selectedItems.some(
                (selectedItem) => getItemId(selectedItem) === itemValue,
              );

              if (isSelected) {
                return selectedItems.filter(
                  (item) => getItemId(item) !== getItemId(newSelectedItem),
                );
              }

              return [...selectedItems, newSelectedItem];
            });
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          if (isFunction(onInputChange)) {
            onInputChange(newInputValue || '');
          }

          break;
        default:
          break;
      }
    },
  });

  return (
    <div className={clsx('relative', hidden ? 'hidden' : '')}>
      {label && (
        <label className={fieldLabel()} {...getLabelProps()}>
          {label}
        </label>
      )}
      <Group
        aria-disabled={isDisabled}
        className={clsx(inputContainer(), errorMessage && 'ring-negative-400')}
        ref={triggerRef}>
        <input
          className={input()}
          dir="auto"
          placeholder={placeholder}
          {...getInputProps(getDropdownProps({ preventKeyAction: isOpen, disabled: isDisabled }))}
        />
        <Pressable
          aria-label="toggle menu"
          className={listBoxTrigger()}
          isDisabled={isDisabled}
          type="button"
          {...getToggleButtonProps()}>
          <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
        </Pressable>
      </Group>
      <div aria-label={label} className="mt-2 flex flex-wrap gap-2" role="grid">
        {internalSelectedItems.map((item, index) => {
          return (
            <div
              className="ring-grey-300 inline-flex items-center gap-x-1 rounded-full bg-white py-1 pl-3 pr-1 text-sm font-medium ring-1"
              key={`selected-item-${index}`}
              role="row"
              {...getSelectedItemProps({
                selectedItem: item,
                index,
              })}>
              {name && <input name={name} type="hidden" value={getItemId(item)} />}
              {getItemLabel(item)}
              <button
                className="hover:bg-grey-100 flex cursor-pointer items-center rounded-full p-1"
                disabled={isDisabled}
                onClick={(e) => {
                  e.stopPropagation();
                  removeSelectedItem(item);
                }}
                type="button">
                {!isDisabled && <Icon className="h-4 w-4 pt-0.5" name="close" />}
              </button>
            </div>
          );
        })}
      </div>
      <ul
        className={twMerge(
          popover(),
          listBox(),
          !(isOpen && items.length) && 'hidden',
          'absolute',
          label ? 'top-[76px]' : 'top-12',
        )}
        style={{ width: triggerWidth }}
        {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => {
            const itemValue = getItemId(item);

            const isSelected = internalSelectedItems.some(
              (selectedItem) => getItemId(selectedItem) === itemValue,
            );

            return (
              <li
                className={twMerge(listBoxItem(), 'group')}
                data-focused={highlightedIndex === index}
                key={`${getItemId(item)}${index}`}
                {...getItemProps({ item, index })}>
                {isFunction(children) ? children(item) : children}
                <Icon className={clsx('opacity-0', isSelected && 'opacity-100')} name="check" />
              </li>
            );
          })}
      </ul>
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
    </div>
  );
}
